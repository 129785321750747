import React, { useState, useEffect } from "react"
import Section from "../../components/Section/Section"
import "./Calculator.scss"

const Calculator = ({ moduleId, backgroundType = "", paddings, heading }) => {
  // Could be cleaner...
  const year = new Date().getFullYear()
  const [selectedBirthMonth, setSelectedBirthMonth] = useState(0)
  const [selectedBirthYear, setSelectedBirthYear] = useState(0)
  const [result, setResult] = useState()

  useEffect(() => {
    getSchoolYears(selectedBirthMonth, selectedBirthYear)
  }, [selectedBirthMonth, selectedBirthYear])

  function getGraduationYears(currentYear) {
    let graduationYears = []
    for (let i = 0; i <= 15; i++) {
      graduationYears.push(currentYear - i)
    }
    return graduationYears.reverse()
  }

  function getSchoolYears(birthMonth, birthYear) {
    let offset = 0
    let kindergartenYear,
      year5,
      year7,
      year10 = 0

    if (birthMonth > 6) {
      offset = 1
    }

    if (selectedBirthMonth !== 0 && selectedBirthYear !== 0) {
      kindergartenYear = Number(birthYear) + 4 + offset
      year5 = Number(birthYear) + 10 + offset
      year7 = Number(birthYear) + 12 + offset
      year10 = Number(birthYear) + 15 + offset
    } else {
      kindergartenYear = "..."
      year5 = "..."
      year7 = "..."
      year10 = "..."
    }

    setResult({
      kindergartenYear,
      year5,
      year7,
      year10,
    })
  }

  const handleFormChange = e => {
    e.preventDefault()
    // determine if the e.target is #dob or #year then update the state
    if (e.target.id === "dob") {
      setSelectedBirthMonth(e.target.value)
    } else if (e.target.id === "year") {
      setSelectedBirthYear(e.target.value)
    }
  }

  return (
    <Section
      id={moduleId}
      paddings={paddings}
      classes={`calculator ${backgroundType}`}
    >
      <div className="container">
        <div className="row padding-left-offset-lg-1 padding-right-offset-lg-1">
          {heading && <h2 class="heading-border">{heading}</h2>}
        </div>
        <div className="row padding-left-offset-lg-1 padding-right-offset-lg-1">
          <form className="calculator__block" onChange={handleFormChange}>
            <div className="calculator__block-dob">
              <label htmlFor="dob">Date of birth</label>
              <div className="custom-select">
                <select name="dob" id="dob">
                  <option value="0">Month</option>
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
              </div>
            </div>

            <div className="calculator__block-year">
              <div className="custom-select">
                <select name="year" id="year">
                  <option value="0">Year</option>
                  {getGraduationYears(year).map((year, index) => (
                    <option key={index} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </form>
        </div>

        {result ? (
          <div className="calculator__results padding-left-offset-lg-1 padding-right-offset-lg-1">
            <div className="calculator__results-block">
              <p>
                Begin Kindergarten in <b>{result.kindergartenYear}</b>
              </p>
              <p>
                Begin Year 5 in <b>{result.year5}</b>
              </p>
              <p>
                Begin Year 7 in <b>{result.year7}</b>
              </p>
              <p>
                Begin Year 10 in <b>{result.year10}</b>
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </Section>
  )
}

export default Calculator

