import React from 'react'
import ValidationMessages from '../ValidationMessages';

const CheckboxField = ({ field, validationMessages = {}, handleChange, formId, formState, setFormState }) => {
  const handleCheckbox = (e) => {
    const { name, value, checked } = e.target;

    if(checked) {
      setFormState({
        ...formState,[name]: value,
      });
    } else {
      const { [name]: value, ...rest } = formState;
      setFormState(rest);

    }
  };
  return (
    <div className={field.layoutGridColumnSpan === 6 ? 'col col-50-desktop option-picker' : 'col col-100 option-picker'}>
        {field.labelPlacement !== 'HIDDEN' && <label htmlFor={`input_${field.databaseId}`}>{field.label}</label>}
        <ul className="checkbox">
          {field.inputs.map((input, index) => {
            return (
              <li key={input.id}>
                <input id={`input_${field.databaseId}-${index}`} name={`input_${field.databaseId}`} type="checkbox" value={input.label} onChange={handleCheckbox}/>
                <label htmlFor={`input_${field.databaseId}-${index}`}>{input.label}</label>
              </li>
            )
          })}
      </ul>
      <ValidationMessages fieldId={field.databaseId} validationMessages={validationMessages} formId={formId} />
    </div>
    );
}

export default CheckboxField;