import React from 'react';
import NewsArticleCard from '../../components/NewsArticleCard/NewsArticleCard';
import ArrowButton from '../../components/ArrowButton/ArrowButton';
import Section from '../../components/Section/Section';
import './NewsBlock.scss';

const NewsBlock = ({ moduleId, paddings, text, link, articles }) => {
  const stories = articles?.slice(0, 3);

  return (
    <Section id={moduleId} classes="news-block" paddings={paddings}>
      <div className="container">
        <div className="news-block__content padding-left-offset-lg-1 padding-right-offset-lg-1">
          {text && <div className="news-block__text" dangerouslySetInnerHTML={{ __html: text }}/>}
          <div className="news-block__button">
            {link && <ArrowButton link={link} />}
          </div>
        </div>
        {stories && <div className="news-block__news">
          { stories.map((story, index) => (
            <NewsArticleCard key={index} index={index} article={story} size="large" isGraphql={true} />
          ))}
        </div>}
      </div>
    </Section>
   );
}

export default NewsBlock;