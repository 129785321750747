import React from 'react';
import { Link } from 'gatsby';
import RightArrow from '../../images/arrow-right.svg';
import ChriateImage from '../ChriateImage';
import './NewsArticleCard.scss';

let CMSURL = 'http:/localhost:8888/rare/mlc-cms';
  process.env.NODE_ENV === 'production' ? CMSURL = 'https://cms.mlc.wa.edu.au/' : CMSURL = 'http://mlc.local';

const NewsArticleCard = ({ index, article, size = null, isGraphql }) => {
  let cardType = article.acf?.card_type;
  let cardTypeImage = article.acf?.card_type_image;

  if (typeof cardType !== 'string') {
    cardType = '';
  }

  if (typeof cardTypeImage !== 'string') {
    cardTypeImage = '';
  }
  let image = article.acf?.image;

  const date = new Date(article.date).toLocaleDateString('en-AU', { day: 'numeric', month: 'short', year: 'numeric' });;


  if(isGraphql) {
    cardType = article.newsArticle?.cardType;
    cardTypeImage = article.newsArticle?.cardTypeImage;

    if (typeof cardType !== 'string') {
      cardType = '';
    }

    if (typeof cardTypeImage !== 'string') {
      cardTypeImage = '';
    }

    image = article.newsArticle?.image;

    return (
      <a target="_blank" href={'/stories/' + article.slug} className={`news-article-card ${index === 0 ? 'light-large' : cardType}${index === 0 ? ' full-width' : ''}${cardTypeImage === 'show-image-full' ? ' overlay' : ''}`}>
        {article.newsArticle?.category ? <div className="news-article-card__label"><p>{article.newsArticle?.category}</p></div> : ''}
        {image && !cardTypeImage.includes('hide') ? <ChriateImage className={`news-article-card__image${cardTypeImage === 'show-image-full' ? ' bg' : ''}`} image={image} /> :''}
        {size !== 'large'&& <div className={`spacer ${cardTypeImage}`}></div>}
        <div className={`news-article-card__content${cardTypeImage === 'show-image-full' ? ' overlay' : ''}${isGraphql && article.excerpt && article.newsArticle?.linkText ? ' stack' : ''}`}>
          <div className="news-article-card__content-text">
            <p className="news-article-card__date">{date}</p>
            {article.title && cardType.includes('large') || size ? <h3 className="news-article-card__title" dangerouslySetInnerHTML={{ __html: article.title }} />
            : <h4 className={`news-article-card__title${image && article.excerpt ? ' spacing' : ''}`} dangerouslySetInnerHTML={{ __html: article.title }} />}
            {article.excerpt && <div className="news-article-card__excerpt" dangerouslySetInnerHTML={{ __html: article.excerpt }} />}
          </div>

          <div className="news-article-card__link">
            {article.newsArticle?.linkText && size && <div>{article.newsArticle?.linkText}</div>}
            <span>
              <RightArrow />
            </span>
          </div>
        </div>

      </a>
    );
  }

  return (
    <a href={article.acf?.external_url ? article.acf?.external_url : '/stories/' + article.slug} target="_blank" className={`news-article-card ${cardType}${size ? ' full-width' : ''}${cardTypeImage === 'show-image-full' ? ' overlay' : ''}`}>
      {article.acf?.category && <div className="news-article-card__label"><p>{article.acf.category}</p></div>}
      {image && !cardTypeImage.includes('hide') ? <img className={`news-article-card__image${cardTypeImage === 'show-image-full' ? ' bg' : ''}`} src={image} /> :''}
      {size !== 'large'&& <div className={`spacer ${cardTypeImage}`}></div>}
      <div className={`news-article-card__content${cardTypeImage === 'show-image-full' ? ' overlay' : ''}`}>
        <div className="news-article-card__content-text">
          <p className="news-article-card__date">{date}</p>
          {article.title.rendered && cardType.includes('large') || size ? <h3 className="news-article-card__title" dangerouslySetInnerHTML={{ __html: article.title.rendered }} />
          : <h4 className={`news-article-card__title${image && article.excerpt?.rendered ? ' spacing' : ''}`} dangerouslySetInnerHTML={{ __html: article.title.rendered }} />}
          {article.excerpt?.rendered && <div className="news-article-card__excerpt" dangerouslySetInnerHTML={{ __html: article.excerpt.rendered }} />}
        </div>

        <div className="news-article-card__link">
          {article.acf?.link_text && size && <div>{article.acf?.link_text}</div>}
          <span>
            <RightArrow />
          </span>
        </div>
      </div>

    </a>
  );
}

export default NewsArticleCard;