import React, { useState, useEffect } from 'react';
import Section from '../../components/Section/Section';
import { Helmet } from "react-helmet";
import loadable from '@loadable/component';
import './GalleryGrid.scss';

const GridItem = loadable(() => import('../../components/GridItem/GridItem'));

const GalleryGrid = ({ moduleId, padding, enableBackgroundColourSplit, galleryGrid }) => {
  const [canRender, setCanRender] = useState(false);


  useEffect(() => setCanRender(true));

  return canRender ? (
    <>
    <Helmet>
      <link rel="stylesheet" href="https://cdn.plyr.io/3.6.2/plyr.css" />
    </Helmet>
    <Section id={moduleId} padding={padding} classes={`gallery-grid${enableBackgroundColourSplit ? ' theme--split' : ''}`}>
      <div className="container">
        <div className="gallery-grid__grid">
          {galleryGrid && galleryGrid.map((item, index) => <GridItem key={index} {...item} index={index} images={galleryGrid} />)}
        </div>
      </div>
    </Section>
    </>
   ) : null;
}

export default GalleryGrid;