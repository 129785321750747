import React, { useRef, useState, useEffect } from 'react'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import ChriateFormFields from './ChriateFormFields'
import OAuth from 'oauth-1.0a'
import './ChriateForm.scss'
import CryptoJS from 'crypto-js';

const ChriateForm = ({ formId }) => {
    const [state, setState] = useState({});
    const [validationMessages, setValidationMessages] = useState({});
    const [validating, setValidating] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    if(typeof submitted !== 'boolean' && submitted !== formId) {
        setSubmitted(false);
        setValidating(false);
        setValidationMessages({});
    }

    const data = useStaticQuery(graphql`
        query FormConfirmationQuery {
        allWpGfForm {
            nodes {
              databaseId
              confirmations {
                page {
                  node {
                    uri
                  }
                }
                message
                type
              }
              submitButton {
                text
              }
            }
          }
        }
    `);

    const form = data.allWpGfForm.nodes.find(form => form.databaseId === formId) || null;
    const confirmations = form.confirmations[0];
    const confirmationType = confirmations.type;

    useEffect(() => {
        return () => {
            setState({});
            setValidationMessages({});
            setValidating(false);
        }
    }, [formId]);

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const formEl = e.target;
        const formData = JSON.stringify(state);
        const consumer_key = process.env.GATSBY_CONSUMER_KEY;
        const consumer_secret = process.env.GATSBY_CONSUMER_SECRET;
        const submissionUrl = `${process.env.GATSBY_CMS_URL}/wp-json/gf/v2/forms/${formId}/submissions`;

        const oauth = new OAuth({
            consumer: {
                key: consumer_key,
                secret: consumer_secret,
            },
            signature_method: 'HMAC-SHA1',
            hash_function(base_string, key) {
                return CryptoJS.HmacSHA1(base_string, key).toString(CryptoJS.enc.Base64);
            },
        });

        const submissionHeaders = oauth.toHeader(oauth.authorize({
            url: submissionUrl,
            method: 'POST',
        }));

        fetch(submissionUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': submissionHeaders.Authorization,
            },
            body: formData
        }).then((res) => {
            if(res.status === 200) {
                navigate(formEl.getAttribute('action'))

                if(confirmationType === 'MESSAGE') {
                    setSubmitted(formId);
                }
            }
        }).catch((error) => {
            console.error(error)
        });
    };

    return (
        <>
            {!submitted ?
            <form action={confirmations.type === 'PAGE' ? confirmations.page.node.uri : '#form-message'} className="chriate-form" onSubmit={handleSubmit}>
                <ChriateFormFields formId={formId} handleChange={handleChange} validationMessages={validationMessages} formState={state} setFormState={setState} />
                <div className="col-100 text-align-right">
                    <button type="submit">{!validating ? form.submitButton.text ? form.submitButton.text : 'Submit'  : 'Submitting...'}</button>
                </div>
            </form>
            :
            <div className="chriate-form__message" id="form-message"dangerouslySetInnerHTML={{__html: confirmations.message}} />

            }
        </>
    )
}

export default ChriateForm