import React, { useRef } from 'react';
import Section from '../../components/Section/Section';
import ChriateForm from '../../components/ChriateForm/ChriateForm';
import './Form.scss'

const Form = ({ moduleId, backgroundType, thankYouPage, formId, text }) => {
  return (
    <Section id={moduleId} classes={`form ${backgroundType} padding-top padding-bottom padding-top-mobile padding-bottom-mobile`}>
      <div className="container">
        {text && <div className="form__text padding-left-offset-lg-1 padding-right-offset-lg-1" dangerouslySetInnerHTML={{ __html: text }} />}
        {formId &&
          <ChriateForm formId={formId} thankYouPage={thankYouPage}/>
        }
      </div>
    </Section>
   );
}

export default Form;