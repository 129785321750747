import React from "react"
import Section from "../../components/Section/Section"
import { Splide, SplideSlide } from "@splidejs/react-splide"
import ContentCard from "../../components/ContentCard/ContentCard"
import "./Testimonials.scss"

const Testimonials = ({
  moduleId,
  backgroundType = "",
  heading,
  testimonials,
}) => {
  return (
    <Section
      id={moduleId}
      classes={`testimonials full-bleed--dark padding-top padding-bottom padding-top-mobile padding-bottom-mobile ${backgroundType}`}
    >
      <div className="container">
        {heading && <h2 className="testimonials__heading">{heading}</h2>}
      </div>

      <div className="container container--left">
        <div className="testimonials__slider">
          <Splide
            options={{
              rewind: true,
              gap: "24px",
              pagination: false,
            }}
          >
            {testimonials &&
              testimonials.map((testimonial, index) => (
                <SplideSlide key={index}>
                  <ContentCard {...testimonial} />
                </SplideSlide>
              ))}
          </Splide>
        </div>
      </div>
    </Section>
  )
}

export default Testimonials

