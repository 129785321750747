import React from "react"
import Section from "../../components/Section/Section"
import ContentCard from "../../components/ContentCard/ContentCard"
import ArrowButton from "../../components/ArrowButton/ArrowButton"
import "./InformationBlock.scss"

const InformationBlock = ({ moduleId, paddings, heading, infos, link }) => {
  console.log(infos)
  return (
    <Section
      id={moduleId}
      classes="information-block full-bleed--light"
      paddings={paddings}
    >
      <div className="container">
        <div
          className="information-block__heading"
          dangerouslySetInnerHTML={{ __html: heading }}
        />
        <div className="information-block__columns">
          {infos &&
            infos.map((info, index) => (
              <ContentCard key={index} index={index} {...info} />
            ))}
        </div>
        {link && (
          <div className="information-block__link">
            <ArrowButton link={link} theme="white" />
          </div>
        )}
      </div>
    </Section>
  )
}

export default InformationBlock
