import React from "react"
import ArrowButton from "../../components/ArrowButton/ArrowButton"
import "./ContentCard.scss"

const ContentCard = ({ index = null, text, link = null, quotee }) => {
  return (
    <div className={`content-card content-card--${index}`}>
      <div dangerouslySetInnerHTML={{ __html: text }} />

      {quotee && <p className="content-card__quotee">{quotee}</p>}

      {link && (
        <div className="content-card__link">
          <ArrowButton link={link} theme="dark" />
        </div>
      )}
    </div>
  )
}

export default ContentCard

