import React from "react"
import Video from "../../components/Video/Video"
import Section from "../../components/Section/Section"
import ChriateImage from "../../components/ChriateImage"
import ArrowButton from "../../components/ArrowButton/ArrowButton"
import "./WysiwygSection.scss"

const ContentType = ({ content: { contentType, videoId, image, text } }) => {
  switch (contentType) {
    case "video":
      return (
        <Video
          videoId={videoId}
          options={{
            autoplay: false,
            controls: [
              "play-large",
              "play",
              "progress",
              "current-time",
              "mute",
              "volume",
              "captions",
              "settings",
              "pip",
              "airplay",
              "fullscreen",
            ],
            hideControls: false,
            volume: 1,
            muted: false,
          }}
        />
      )
    case "image":
      return <ChriateImage image={image} />
    case "text":
      const headingCentred = text?.includes('<h3 style="text-align: center;">')
        ? " heading-center"
        : text?.includes('<h2 style="text-align: center;">')
        ? " heading-center"
        : ""
      return (
        <div
          className={`article__content-text ${headingCentred}`}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      )
    default:
      return null
  }
}

const WysiwygSection = ({ moduleId, backgroundType, paddings, contents }) => {
  return (
    <Section
      moduleId={moduleId}
      paddings={paddings}
      classes={`${
        backgroundType
          ? `wysiwyg-section ${backgroundType}`
          : "wysiwyg-section wysiwyg-section--article"
      }`}
    >
      <div className={`${backgroundType ? "container" : "container-fluid"}`}>
        <div
          className={`${
            backgroundType
              ? "padding-left-offset-lg-1 padding-right-offset-lg-1"
              : ""
          }`}
        >
          {contents?.length &&
            contents.map((data, id) => (
              <div key={id} className="article__content-block">
                <ContentType content={data} />
                {data?.link && <ArrowButton link={data.link} />}
              </div>
            ))}
        </div>
      </div>
    </Section>
  )
}

export default WysiwygSection

