import React from "react"
import { Link } from "gatsby"
import ChriateImage from "../../components/ChriateImage"
import Section from "../../components/Section/Section"
import RightArrow from "../../images/arrow-right.svg"
import "./FeaturedCtas.scss"

const FeaturedCtas = ({ moduleId, ctas }) => {
  return (
    <Section id={moduleId} classes="featured-ctas">
      <div className="container-fluid">
        {ctas &&
          ctas.map(
            (
              { backgroundColour, foregroundImage, backgroundImage, link },
              index,
            ) => (
              <a
                className={`featured-ctas__card ${backgroundColour}`}
                key={index}
                href={link?.url ? link.url : ""}
                target={link?.target ? link.target : ""}
              >
                <h4 className="featured-ctas__card-heading">
                  <span>{link?.title ? link.title : ""}</span>
                  <span className="circle">
                    <RightArrow />
                  </span>
                </h4>
                <div className="featured-ctas__card-images">
                  <div className="featured-ctas__card-background">
                    <ChriateImage image={backgroundImage} objectFit="contain" />
                  </div>
                  <div className="featured-ctas__card-foreground">
                    <ChriateImage image={foregroundImage} objectFit="contain" />
                  </div>
                </div>
              </a>
            ),
          )}
      </div>
    </Section>
  )
}

export default FeaturedCtas

