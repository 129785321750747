import React, { useState, useEffect } from 'react';
import './CustomSelect.scss';

const CustomSelect = ({ id = 'default', options, selectedOption, setSelectedOption }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isDropdownOpen && !event.target.closest(`.custom-select-${id}`)) {
        setIsDropdownOpen(false);
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    }

  },[isDropdownOpen]);

  return (
    <>
      <div className={`custom-select custom-select-${id}`}>
        <span className="custom-select__selected" onClick={(e) => {setIsDropdownOpen(!isDropdownOpen)}}>
          {selectedOption.optionName ? selectedOption.optionName : selectedOption.name}
          <span className={`arrow${isDropdownOpen ? ' up' : ''}`}></span>
        </span>
        <div className={`custom-select__options${isDropdownOpen ? ' open' : ''}`}>
          {options && options.map((option, index) => (
            <div className={`custom-select__option ${index === selectedOption ? 'custom-select__option--selected' : ''}`} key={index} onClick={((e) => {setSelectedOption(option); setIsDropdownOpen(!isDropdownOpen)})}>
              {option.optionName ? option.optionName : option.name}
            </div>
          ))}
        </div>
      </div>
    </>
   );
}

export default CustomSelect;