import React from 'react';
import Section from '../../components/Section/Section';
import ArrowButton from '../../components/ArrowButton/ArrowButton';
import './ColumnText.scss';

const ColumnText = ({ moduleId, paddings, backgroundType, headerSize, heading, textColumn1, textColumn2, link }) => {
  return (
    <Section id={moduleId} classes={`column-text ${backgroundType}`} paddings={paddings}>
      <div className="container">
        <div className="column-text__heading">
          <h2 className={`${headerSize} heading-border`}>{heading}</h2>
        </div>

        <div className="column-text__columns">
          <div className={`column-text__column${textColumn2 ? '' : ' full'}`}>
            <div className="column-text__text" dangerouslySetInnerHTML={{__html: textColumn1 }} />
          </div>
          {textColumn2 && <div className="column-text__column">
            <div className="column-text__text" dangerouslySetInnerHTML={{ __html: textColumn2 }} />
          </div>}
        </div>
        {link?.url && <ArrowButton link={link} theme={backgroundType === 'theme' ? 'white' : backgroundType} />}
      </div>
    </Section>
   );
}

export default ColumnText;