import React from "react"
import Section from "../../components/Section/Section"
import TableItem from "../../components/TableItem/TableItem"
import ArrowButton from "../../components/ArrowButton/ArrowButton"
import "./Table.scss"

const Table = ({
  moduleId,
  backgroundType = "",
  paddings,
  text,
  link,
  table,
  centreAlignTableHeadings = false,
}) => {
  const headingCentred = text?.includes('<h2 style="text-align: center;">')
    ? " heading-center"
    : ""

  return (
    <Section
      id={moduleId}
      paddings={paddings}
      classes={`table ${backgroundType}`}
    >
      <div className="container">
        <div
          className={`table__text padding-left-offset-lg-1 padding-right-offset-lg-1${headingCentred}`}
          dangerouslySetInnerHTML={{ __html: text }}
        />
        <div
          className={`table__tables padding-left-offset-lg-1 padding-right-offset-lg-1${
            centreAlignTableHeadings ? " centred-heading" : ""
          }`}
        >
          {table && <TableItem table={table} />}
        </div>
        <div className="table__button padding-left-offset-lg-1 padding-right-offset-lg-1">
          {link && (
            <ArrowButton
              link={link}
              theme={backgroundType === "dark-green" ? "dark" : "white"}
            />
          )}
        </div>
      </div>
    </Section>
  )
}

export default Table

