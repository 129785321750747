import React from "react"
import ChriateImage from "../../components/ChriateImage"
import "./FullWidthImage.scss"

const FullWidthImage = ({ image, imageHeight = "auto" }) => {
  return (
    <ChriateImage image={image} className={`full-width-image ${imageHeight}`} />
  )
}

export default FullWidthImage

