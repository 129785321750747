import React from "react"
import Section from "../../components/Section/Section"
// import AccordionItem from '../../components/AccordionItem/AccordionItem';
import loadable from "@loadable/component"
import "./Accordion.scss"

const AccordionItem = loadable(() =>
  import("../../components/AccordionItem/AccordionItem")
)

const Accordion = ({
  moduleId,
  backgroundType = "",
  paddings,
  text,
  accordion,
}) => {
  return (
    <Section
      id={moduleId}
      classes={`accordion ${backgroundType}`}
      paddings={paddings}
    >
      <div className="container">
        <div
          className="accordion__content padding-left-offset-lg-1 padding-right-offset-lg-1"
          dangerouslySetInnerHTML={{ __html: text }}
        />
        <div className="accordion__accordion padding-left-offset-lg-1 padding-right-offset-lg-1">
          {accordion &&
            accordion.map((data, index) => (
              <AccordionItem
                key={index}
                theme={backgroundType === "dark-green" ? "dark" : "white"}
                {...data}
              />
            ))}
        </div>
      </div>
    </Section>
  )
}

export default Accordion

