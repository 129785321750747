import React from "react"
import Section from "../../components/Section/Section"
import CtaItem from "../../components/CtaItem/CtaItem"
import ArrowButton from "../../components/ArrowButton/ArrowButton"
import "./Cta.scss"

const Cta = ({
  moduleId,
  paddings,
  backgroundType,
  text,
  ctas,
  link = null,
}) => {
  return (
    <Section
      id={moduleId}
      classes={`cta ${backgroundType}`}
      paddings={paddings}
    >
      <div className="container">
        <div
          className="cta__content"
          dangerouslySetInnerHTML={{ __html: text }}
        />
        <div className="cta__columns">
          {ctas &&
            ctas.map((cta, index) => (
              <CtaItem
                key={index}
                index={index}
                theme={backgroundType === "dark-green" ? "white" : "dark"}
                {...cta}
              />
            ))}
        </div>
        {link?.url && (
          <div className="cta__link">
            <ArrowButton
              link={link}
              theme={backgroundType === "dark-green" ? "dark" : "white"}
            />
          </div>
        )}
      </div>
    </Section>
  )
}

export default Cta

