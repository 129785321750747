import React from 'react'
import ValidationMessages from '../ValidationMessages';

const RadioField = ({ field, validationMessages = {}, handleChange, formState, formId }) => {
  return (
    <div className={field.layoutGridColumnSpan === 6 ? `col col-50-desktop option-picker${field.cssClass ? ' ' + field.cssClass : ''}` : `col col-100 option-picker${field.cssClass ? ' ' + field.cssClass : ''}`}>
        {field.labelPlacement !== 'HIDDEN' && <label htmlFor={`input_${field.databaseId}`}>{field.label}</label>}
        <ul className="radio">
          {field.choices.map((input, index) => {
            return (
              <li key={field.databaseId + '-' + index}>
                <input id={`input_${field.databaseId}-${index}`} name={`input_${field.databaseId}`} type="radio" value={input.value} onChange={handleChange} checked={formState[`input_${field.databaseId}`] === input.value ? true : false} required={index === 0 ? true : false}/>
                <label htmlFor={`input_${field.databaseId}-${index}`} dangerouslySetInnerHTML={{ __html: input.text }}/>
              </li>
            )
          })}
      </ul>
      <ValidationMessages fieldId={field.databaseId} validationMessages={validationMessages} formId={formId} />
    </div>
    );
}

export default RadioField;