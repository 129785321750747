import React, { useState } from 'react';
import Section from '../../components/Section/Section';
import ChriateForm from '../../components/ChriateForm/ChriateForm';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import './SupportBlock.scss';


const SupportBlock = ({ moduleId, heading, options }) => {
  const [selectedOption, setSelectedOption] = useState({optionName: '- Please Select Your Option -'}); // Module State... shared between components

  return (
    <Section id={moduleId} classes="support-block padding-top padding-bottom  padding-top-mobile padding-bottom-mobile theme-wave--shallow">
      <div className="container">
        {heading && <h3 className="support-block__heading">{heading}</h3>}
        <div className="support-block__dropdown">
          <CustomSelect options={options} selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
        </div>

        <div className="support-block__content">
          {selectedOption?.text && <p className="support-block__content-text" dangerouslySetInnerHTML={{__html: selectedOption.text }}/>}
          {selectedOption.addForm && selectedOption.formId ?
            <div className="support-block__form">
              <ChriateForm formId={selectedOption.formId} thankYouPage={selectedOption?.thankYouPage} />
            </div>
            : null
          }
        </div>
      </div>
    </Section>
   );
}

export default SupportBlock;