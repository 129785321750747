import React, { useState } from 'react'
import ValidationMessages from '../ValidationMessages';

const SelectField = ({ field, validationMessages = {}, handleChange, formId }) => {
  const [selected, setSelected] = useState('')

  const handleSelect = (e) => {
    handleChange(e);
    setSelected(e.target.value);
  }
  return (
    <div className={field.layoutGridColumnSpan === 6 ? 'col col-50-desktop' : 'col col-100'}>
      {field.labelPlacement !== 'HIDDEN' && <label htmlFor={`input_${field.databaseId}`}>{field.label}</label>}
      <select name={`input_${field.databaseId}`} id="year-level" className="text-input" onChange={handleSelect} value={selected} required={field.isRequired}>
        {field.placeholder ? <option value="" selected>{field.placeholder}</option> : ''}
      {field.choices ? field.choices.map((choice, index) => {
        return (
          <option key={index} value={choice.value}>{choice.text}</option>
        )
      }) : null}
      </select>
      <ValidationMessages fieldId={field.databaseId} validationMessages={validationMessages} formId={formId}/>
    </div>
    );
}

export default SelectField;