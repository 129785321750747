import React from "react"
import loadable from "@loadable/component"

const GoogleCalendarClient = loadable(() => import("./GoogleCalendarClient"))

const GoogleCalendar = props => {
  return <GoogleCalendarClient {...props} />
}

export default GoogleCalendar
