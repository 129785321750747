import React from "react"
import Section from "../../components/Section/Section"
import ChriateImage from "../../components/ChriateImage"
import ArrowButton from "../../components/ArrowButton/ArrowButton"
import "./FullWidthCta.scss"

const FullWidthCta = ({
  moduleId,
  paddings,
  imagePosition,
  backgroundColour = "dark-green",
  image,
  text,
  link,
}) => {
  const headingCentred = text?.includes('<h2 style="text-align: center;">')
    ? " heading-center"
    : ""
  return (
    <Section
      id={moduleId}
      classes={`full-width-cta ${imagePosition} ${backgroundColour}`}
      paddings={paddings}
    >
      <div
        className={`container-fluid flex ${
          imagePosition === "left" ? "right" : "left"
        }`}
      >
        <div className="full-width-cta__column padding-left-offset-lg-1 text">
          <div
            className={`full-width-cta__text${headingCentred}`}
            dangerouslySetInnerHTML={{ __html: text }}
          />
          <div className="full-width-cta__link">
            {link?.url && (
              <ArrowButton
                link={link}
                theme={backgroundColour === "dark-green" ? "dark" : "white"}
              />
            )}
          </div>
        </div>
        <div className="full-width-cta__column image">
          <ChriateImage image={image} />
        </div>
      </div>
    </Section>
  )
}

export default FullWidthCta
