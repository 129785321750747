import React, { useState, useEffect } from "react"
import "./Spacer.scss"

const Spacer = ({
  backgroundType = "",
  heightDesktop = 0,
  heightMobile = 0,
}) => {
  const [matches, setMatches] = useState(null)

  useEffect(() => {
    setMatches(window.matchMedia("(min-width: 1024px)").matches)

    window
      .matchMedia("(min-width: 1024px)")
      .addEventListener("change", e => setMatches(e.matches))

    return () => {
      window
        .matchMedia("(min-width: 1024px)")
        .removeEventListener("change", e => setMatches(e.matches))
    }
  }, [])

  return (
    <div
      className={`spacer ${backgroundType}`}
      style={{
        height: matches ? heightDesktop : heightMobile,
      }}
    />
  )
}

export default Spacer
