import React, { useState } from "react"
import ChriateImage from "../ChriateImage"
import ArrowButton from "../ArrowButton/ArrowButton"
import "./CtaItem.scss"

const CtaItem = ({
  index,
  size,
  image,
  text,
  enableTextOverlay,
  link,
  theme = "dark",
}) => {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <div
      className={`cta-item cta-item--${index} ${size}${
        !image ? " texture" : ""
      } ${isHovered ? " hovering" : ""}`}
    >
      {image && (
        <div
          className={`cta-item__image${enableTextOverlay ? " overlay" : ""}`}
        >
          <ChriateImage image={image} />
        </div>
      )}
      <div className={`cta-item__text${enableTextOverlay ? " overlay" : ""}`}>
        <div dangerouslySetInnerHTML={{ __html: text }} />
        {link && (
          <div className="cta-item__link">
            {link.title && (
              <ArrowButton
                link={link}
                theme={theme}
                hoverEvent={setIsHovered}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default CtaItem

