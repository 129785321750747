import React, { useState, useEffect } from "react"
import ChriateImage from "../../components/ChriateImage"
import Section from "../../components/Section/Section"
import Popup from "reactjs-popup"
import { Splide, SplideTrack, SplideSlide } from "@splidejs/react-splide"
import RightArrow from "../../images/arrow-right.svg"
import "./ImageCarousel.scss"

const ImageCarousel = ({
  moduleId,
  backgroundType = "white",
  paddings,
  slidesPerView = 4,
  slidesPerViewMobile = 2,
  dots = false,
  arrows = false,
  autoplay = false,
  images = [],
}) => {
  const [matches, setMatches] = useState(null)

  useEffect(() => {
    setMatches(window.matchMedia("(min-width: 1024px)").matches)

    window
      .matchMedia("(min-width: 1024px)")
      .addEventListener("change", e => setMatches(e.matches))

    return () => {
      window
        .matchMedia("(min-width: 1024px)")
        .removeEventListener("change", e => setMatches(e.matches))
    }
  }, [])

  return (
    <Section
      id={moduleId}
      paddings={paddings}
      classes={`image-carousel ${backgroundType}`}
    >
      <div className="container">
        <Splide
          hasTrack={false}
          options={{
            rewind: true,
            pagination: dots,
            arrows: arrows,
            autoplay: autoplay,
            perPage: matches ? slidesPerView : slidesPerViewMobile,
            gap: 15,
          }}
        >
          <SplideTrack>
            {images.map((image, index) => {
              return (
                <SplideSlide key={index}>
                  <Popup
                    trigger={
                      <div className="image-carousel__image">
                        <ChriateImage image={image} objectFit="contain" />
                      </div>
                    }
                    modal
                    nested
                  >
                    {close => (
                      <div className="image-carousel__modal">
                        <button className="close" onClick={close}></button>

                        <div className="image-carousel__modal-image">
                          <ChriateImage image={image} objectFit="contain" />
                        </div>
                      </div>
                    )}
                  </Popup>
                </SplideSlide>
              )
            })}
          </SplideTrack>

          <div className="splide__arrows">
            <button className="splide__arrow splide__arrow--prev">
              <span className="arrow">
                <RightArrow />
              </span>
            </button>
            <button className="splide__arrow splide__arrow--next">
              <span className="arrow">
                <RightArrow />
              </span>
            </button>
          </div>
        </Splide>
      </div>
    </Section>
  )
}

export default ImageCarousel
