import React from 'react';
import { Link } from 'gatsby';
import './Breadcrumbs.scss';

const Breadcrumbs = ({ currentUrl, currentTitle, ancestors, categoryName }) => {

  // ! care - converting category name to slug - this is not a good solution - it's just a quick fix...
  // ! as the category data from graphQL isn't consistently outputting the correct data.
  // const reversedAncestors = ancestors?.nodes.reverse();
  

  const slug = categoryName?.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');

  return currentUrl !== '/' && currentUrl && currentTitle ? (
    <nav className="breadcrumbs">
      <ul>
        <li><Link to="/">Home</Link><p>{`>`}</p></li>
        {
          ancestors?.nodes.length ? ancestors.nodes.reverse().map((ancestor, index) => {
            return (
              <li key={index}><Link to={ancestor.uri}>{ancestor.title}</Link><p>{`>`}</p></li>
            )
          }) : null
        }

        {
          categoryName && (
            <li><Link to={`/stories/?category=${slug}`}>{categoryName}</Link><p>{`>`}</p></li>
          )
        }

        <li><span>{currentTitle}</span></li>

      </ul>
      </nav>

   ) : null;
}

export default Breadcrumbs;