import React from "react"
import loadable from "@loadable/component"

const VideoClient = loadable(() => import("./VideoClient"))

const Video = props => {
  return <VideoClient {...props} />
}

export default Video

