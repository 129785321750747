import React from "react"
import Section from "../../components/Section/Section"
import ChriateImage from "../../components/ChriateImage"
import ArrowButton from "../../components/ArrowButton/ArrowButton"
import "./ImageAndText.scss"

const ImageAndText = ({
  moduleId,
  paddings,
  backgroundType,
  imagePosition,
  fullWidthHeading = false,
  heading,
  image,
  text,
  link,
}) => {
  return (
    <Section
      id={moduleId}
      classes={`image-and-text ${imagePosition} ${backgroundType}`}
      paddings={paddings}
    >
      <div className="container">
        <div
          className={`image-and-text__heading ${
            fullWidthHeading ? "full" : "padding-left-offset-lg-1"
          }`}
          dangerouslySetInnerHTML={{ __html: heading }}
        />

        <div
          className={`image-and-text__columns ${
            imagePosition === "right" ? "padding-left-offset-lg-1" : ""
          }`}
        >
          <div className="image-and-text__column image">
            {image && <ChriateImage image={image} />}
          </div>

          <div
            className={`image-and-text__column text ${
              imagePosition === "left"
                ? "padding-left-offset-lg-1"
                : "padding-right-offset-lg-1"
            }`}
          >
            <div dangerouslySetInnerHTML={{ __html: text }} />
            {link && <ArrowButton link={link} />}
          </div>
        </div>
      </div>
    </Section>
  )
}

export default ImageAndText

