import React, { useState, useEffect } from "react"
import gsap from "gsap"
import { graphql, useStaticQuery } from "gatsby"
import Section from "../../components/Section/Section"
import ArrowButton from "../../components/ArrowButton/ArrowButton"
import ChriateImage from "../../components/ChriateImage"
import BusIcon from "../../images/bus-icon.svg"
import ParkingIcon from "../../images/p-icon.svg"
import TrainIcon from "../../images/train-icon.svg"
import Arrow from "../../images/arrow-right.svg"
import "./InteractiveMap.scss"

// !All in one component...
const InteractiveMap = ({
  moduleId,
  backgroundType = "",
  paddings,
  text,
  link,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  let processedMarkers
  const {
    wp: {
      acfOptionsGeneralSettings: {
        themeSettings: { map, mapMobile, markers },
      },
    },
  } = useStaticQuery(graphql`
    query {
      wp {
        acfOptionsGeneralSettings {
          themeSettings {
            map {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            mapMobile {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            markers {
              markerType
              markerPosition
              markerPositionMobile
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(aspectRatio: 1.77)
                  }
                }
              }
              description
              label
              infos {
                icon {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                label
              }
            }
          }
        }
      }
    }
  `)

  if (markers) {
    // convert position to array;
    processedMarkers = markers.map(marker => {
      const markerPosition = marker.markerPosition
        ? marker.markerPosition.split(",").map(item => {
            return parseFloat(item)
          })
        : ""

      const markerPositionMobile = marker.markerPositionMobile
        ? marker.markerPositionMobile.split(",").map(item => {
            return parseFloat(item)
          })
        : ""

      return {
        ...marker,
        markerPosition,
        markerPositionMobile,
      }
    })

    // sort
    processedMarkers.sort((a, b) => {
      return a.markerPosition[0] - b.markerPosition[0]
    })
  }

  useEffect(() => {
    handleMaxHeight()
    window.addEventListener("resize", handleMaxHeight)

    return () => {
      window.removeEventListener("resize", handleMaxHeight)
    }
  }, [])

  useEffect(() => {
    if (currentIndex) {
      document
        .querySelector(".interactive-map__details")
        .classList.add("active")
    }
    handleTransitionIn(currentIndex)
  }, [currentIndex])

  function handleMaxHeight() {
    const arrowsHeight = document.querySelector(
      ".interactive-map__arrows"
    ).offsetHeight
    const detailsBlocks = document.querySelectorAll(
      ".interactive-map__details-block"
    )
    let maxHeight = 0

    detailsBlocks.forEach(block => {
      if (block.offsetHeight > maxHeight) {
        maxHeight = block.offsetHeight
      }
    })

    document.querySelector(".interactive-map__details").style.height = `${
      maxHeight + arrowsHeight
    }px`
  }

  function incrementCurrentIndex() {
    if (currentIndex === markers.length - 1) {
      handleTransitionOut(false, 0)
    } else {
      handleTransitionOut(false, currentIndex + 1)
    }
  }

  function decrementCurrentIndex(e) {
    if (currentIndex === 0) {
      handleTransitionOut(false, markers.length - 1)
    } else {
      handleTransitionOut(false, currentIndex - 1)
    }
  }

  function handleTransitionIn(index) {
    const tl = gsap.timeline({
      onStart: () => {
        // const activeElement = document.querySelectorAll('.interactive-map__details-block')[index];
        // const arrowsHeight = document.querySelector('.interactive-map__arrows').offsetHeight;
        // const height = activeElement?.offsetHeight ? activeElement.offsetHeight : 0;
        // document.querySelector('.interactive-map__details').style.height = `calc(${height}px + ${arrowsHeight}px)`;
      },
    })
    const ease = "power2.inOut"

    tl.fromTo(
      ".interactive-map__details-block.active h3",
      {
        opacity: 0,
        duration: 0.5,
        ease: ease,
      },
      {
        opacity: 1,
        duration: 0.5,
        ease: ease,
      }
    )
      .fromTo(
        ".interactive-map__details-block.active h3",
        {
          duration: 0.7,
          y: 50,
          ease: ease,
        },
        {
          duration: 0.7,
          y: 0,
          ease: ease,
        },
        0
      )
      .fromTo(
        ".interactive-map__details-block.active .interactive-map__details-image",
        {
          opacity: 0,
          duration: 0.5,
          ease: ease,
        },
        {
          opacity: 1,
          duration: 0.5,
          ease: ease,
        },
        0.03
      )
      .fromTo(
        ".interactive-map__details-block.active .interactive-map__details-image",
        {
          duration: 0.7,
          y: 50,
          ease: ease,
        },
        {
          duration: 0.7,
          y: 0,
          ease: ease,
        },
        0.03
      )
      .fromTo(
        ".interactive-map__details-block.active .interactive-map__details-description",
        {
          opacity: 0,
          duration: 0.5,
          ease: ease,
        },
        {
          opacity: 1,
          duration: 0.5,
          ease: ease,
        },
        0.04
      )
      .fromTo(
        ".interactive-map__details-block.active .interactive-map__details-description",
        {
          duration: 0.7,
          y: 50,
          ease: ease,
        },
        {
          duration: 0.7,
          y: 0,
          ease: ease,
        },
        0.04
      )
      .fromTo(
        ".interactive-map__details-block.active .interactive-map__details-info",
        {
          opacity: 0,
          duration: 0.5,
          ease: ease,
        },
        {
          opacity: 1,
          duration: 0.5,
          ease: ease,
        },
        0.05
      )
      .fromTo(
        ".interactive-map__details-block.active .interactive-map__details-info",
        {
          duration: 0.7,
          y: 50,
          ease: ease,
        },
        {
          duration: 0.7,
          y: 0,
          ease: ease,
        },
        0.05
      )
  }

  function handleTransitionOut(e, index) {
    if (document.querySelector(".interactive-map__marker.active")) {
      if (e.target) {
        document
          .querySelector(".interactive-map__marker.active")
          ?.classList.remove("active")
        e.target.classList.add("active")
      } else {
        document
          .querySelector(".interactive-map__marker.active")
          ?.classList.remove("active")
        document
          .querySelector(`.interactive-map__marker[data-index="${index}"]`)
          ?.classList.add("active")
      }

      const ease = "power2.inOut"
      const tl = gsap.timeline({
        onComplete: () => {
          setCurrentIndex(index)
        },
      })

      tl.to(".interactive-map__details-block h3", {
        opacity: 0,
        duration: 0.5,
        ease: ease,
      })
        .to(
          ".interactive-map__details-block h3",
          {
            duration: 0.7,
            y: -50,
            ease: ease,
          },
          0
        )
        .to(
          ".interactive-map__details-block .interactive-map__details-image",
          {
            opacity: 0,
            duration: 0.5,
            ease: ease,
          },
          0.03
        )
        .to(
          ".interactive-map__details-block .interactive-map__details-image",
          {
            duration: 0.6,
            y: -50,
            ease: ease,
          },
          0.03
        )
        .to(
          ".interactive-map__details-block .interactive-map__details-description",
          {
            opacity: 0,
            duration: 0.5,
            ease: ease,
          },
          0.06
        )
        .to(
          ".interactive-map__details-block .interactive-map__details-description",
          {
            duration: 0.7,
            y: -50,
            ease: ease,
          },
          0.06
        )
        .to(
          ".interactive-map__details-block .interactive-map__details-info",
          {
            opacity: 0,
            duration: 0.7,
            ease: ease,
          },
          0.09
        )
        .to(
          ".interactive-map__details-block .interactive-map__details-info",
          {
            duration: 0.7,
            y: -50,
            ease: ease,
          },
          0.09
        )
    } else {
      if (e.target) {
        e.target.classList.add("active")
      }

      setCurrentIndex(index)
    }
  }

  return (
    <Section
      moduleId={moduleId}
      paddings={paddings}
      classes={`interactive-map ${backgroundType}`}
    >
      <div className="container">
        <div className="interactive-map__text padding-left-offset-lg-1 padding-right-offset-lg-1">
          {text && <div dangerouslySetInnerHTML={{ __html: text }} />}
          {link && <ArrowButton link={link} />}
        </div>
      </div>
      <div className="container">
        <div className="interactive-map__map">
          {map && (
            <ChriateImage
              image={map}
              onClick={() => {
                setCurrentIndex(-1)
              }}
            />
          )}
          {mapMobile && (
            <ChriateImage
              className="mobile"
              image={mapMobile}
              onClick={() => {
                setCurrentIndex(-1)
              }}
            />
          )}

          {processedMarkers?.length &&
            processedMarkers.map((marker, index) => (
              <div
                key={index}
                className={`interactive-map__marker${
                  currentIndex === index ? " active" : ""
                }`}
                data-index={index}
                onClick={e => {
                  handleTransitionOut(e, index)
                }}
                style={{
                  left: marker.markerPosition[0] + "%",
                  top: marker.markerPosition[1] + "%",
                  "--mobile-left": marker.markerPositionMobile[0] + "%",
                  "--mobile-top": marker.markerPositionMobile[1] + "%",
                }}
              >
                <p>
                  <span>{marker.label}</span>
                </p>
                {marker.markerType === "parking-icon" ? (
                  <ParkingIcon />
                ) : marker.markerType === "bus-icon" ? (
                  <BusIcon />
                ) : marker.markerType === "train-icon" ? (
                  <TrainIcon />
                ) : null}
              </div>
            ))}
        </div>

        <div className="interactive-map__details active">
          {processedMarkers?.length &&
            processedMarkers.map(
              ({ label, image, description, infos }, index) => (
                <div
                  key={index}
                  data-index={index}
                  className={`interactive-map__details-block${
                    currentIndex === index ? " active" : ""
                  }`}
                >
                  <div className="interactive-map__details-wrapper">
                    {label && <h3>{label}</h3>}
                    {image && (
                      <ChriateImage
                        className="interactive-map__details-image"
                        image={image}
                      />
                    )}
                    <p className="interactive-map__details-description">
                      {description}
                    </p>
                    {infos?.length && (
                      <div className="interactive-map__details-grid">
                        {infos.map(({ label, icon }, index) => (
                          <div
                            key={index}
                            className="interactive-map__details-info"
                          >
                            <ChriateImage image={icon} objectFit="contain" />
                            {label && (
                              <p dangerouslySetInnerHTML={{ __html: label }} />
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              )
            )}
          <div className="interactive-map__arrows">
            <button className="arrow-left" onClick={decrementCurrentIndex}>
              <Arrow />
            </button>
            <button className="arrow-right" onClick={incrementCurrentIndex}>
              <Arrow />
            </button>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default InteractiveMap

