import React from 'react';
import './TableItem.scss';

const TableItem = React.forwardRef(({ table }, ref) => {
  const totalHeaderLength = table.header.reduce((acc, cur) => acc + cur.length, 0);
  const totalBodyLength = table.body.reduce((acc, cur) => acc + cur.replace(/ <-----> /g, '').length, 0);

  if (totalHeaderLength || totalBodyLength) {
    return (
      <div ref={ref} className="table-item">
        <table className="table-item__table">
          {totalHeaderLength ? <thead>
            <tr>
              {table.header.map((item, index) => (
                item ? <th key={index}>{item}</th> : <th key={index}>&nbsp;</th>
              ))}
            </tr>
            </thead> : null}
            {totalBodyLength ? <tbody>
              {table.body.map((item, index) => (
                <tr key={index}>
                  {item.length ? item.split(' <-----> ').map((text, index) => (
                    text ? <td key={index} dangerouslySetInnerHTML={{ __html: text }}/> : <td key={index}>&nbsp;</td>
                  )) : null}
                </tr>
              ))}
            </tbody> : null}
        </table>
      </div>
    );
  }

  return '';
});

export default TableItem;