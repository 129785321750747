import React from "react"
import Section from "../../components/Section/Section"
import ChriateImage from "../../components/ChriateImage"
import "./WysiwygBlock.scss"

const WysiwygBlock = ({
  moduleId,
  backgroundType,
  paddings,
  content,
  image,
  content1,
}) => {
  const headingCentred = content?.includes('<h2 style="text-align: center;">')
    ? " heading-center"
    : ""

  const headingCentred1 = content1?.includes('<h2 style="text-align: center;">')
    ? " heading-center"
    : ""

  return (
    <Section
      id={moduleId}
      classes={`wysiwyg-block ${backgroundType}`}
      paddings={paddings}
    >
      <div className="container">
        <div className="wysiwyg-block__content padding-left-offset-lg-1 padding-right-offset-lg-1">
          {content && (
            <div
              className={headingCentred}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          )}
          {image && <ChriateImage image={image} />}
          {content1 && (
            <div
              className={headingCentred1}
              dangerouslySetInnerHTML={{ __html: content1 }}
            />
          )}
        </div>
      </div>
    </Section>
  )
}

export default WysiwygBlock

