import React from 'react'
import ValidationMessages from '../ValidationMessages';

const NameField = ({ field, validationMessages = {}, handleChange, formId }) => {
  return (
    <div className="col col-100 name-flex">
      {field.labelPlacement !== 'HIDDEN' && <label htmlFor={`input_${field.databaseId}`}>{field.label}</label>}
      {field.inputs.map(input => {
        if(input.label === 'First' || input.label === 'Last') {
          return (
            <div key={input.id} className="col-50-desktop">
              <input className="text-input" type="text" name={`input_${input.id}`} placeholder={input.label === 'First' ? 'First Name' : 'Last Name'} onChange={handleChange} required={field.isRequired} />
            </div>
          )
        }
      })}
      <ValidationMessages fieldId={field.databaseId} validationMessages={validationMessages} formId={formId} />
    </div>
    );
}

export default NameField;